import { useScroll, useTransform, motion } from "framer-motion";
import "../../../App.css";

const About: React.FC = () => {
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [300, 800], [200, 0]);

  return (
    <section className="pt-10 md:py-20 bg-white min-h-[400px] md:min-h-[630px] overflow-hidden">
      <div className="container mx-auto px-4 md:px-16">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {/* Text Content - Now at the top for mobile */}
          <div className="w-full lg:w-1/2 mb-12 lg:mb-0 order-1 lg:order-2">
            <h1 className="text-[#262626] text-5xl myriad-pro-light md:text-6xl lg:text-[78px] mb-6 text-start lg:text-end">
              A <span className="myriad-pro-bold">market</span> on your phone
            </h1>
            <p className="text-[#575757] w-[310px] lg:w-full text-[16px] md:text-[19px] text-start  lg:text-end myriad-pro-regular lg:px-0">
              Zambia's gateway to seamless shopping, designed to bring the best
              of e-commerce to your fingertips
            </p>
            <div className="lg:flex justify-start hidden lg:justify-end items-start lg:items-end mt-6">
              <button className="bg-black mt-4 text-white px-10 md:px-20 py-4 md:py-5 rounded-full hover:bg-black/90 transition font-semibold">
                Get updated
              </button>
            </div>
          </div>

          {/* Image - Below text on mobile */}
          <div className="w-full lg:w-1/2 relative order-2 lg:order-1 lg:mt-0">
            <motion.img
              style={{ y }}
              src="https://ik.imagekit.io/gulany/gulany/Untitled%20design%20(39).png?updatedAt=1730044021504"
              alt="About Us"
              className="w-full h-auto object-cover relative left-[-20px] top-[-50px] lg:absolute lg:left-[-100px] lg:top-[-150px]"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
