import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../../pages/home";
import StanelyCups from "../../pages/stanelycups";
import SmartphoneScreenMagnifier from "../../pages/smartphoneScreenMagnifier";

function AppRouter() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/stanelycups" element={<StanelyCups />} />
          <Route
            path="/smartphoneScreenMagnifier"
            element={<SmartphoneScreenMagnifier />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default AppRouter;
