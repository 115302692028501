import { Link } from "react-router-dom";
import { logo } from "../../assets/images";

const NavBar = () => {
  return (
    <nav className="flex justify-between items-center py-6 px-4 lg:px-28">
      <div className="flex items-center space-x-4">
        {/* Logo */}
        <div className="text-white text-3xl">
          {" "}
          <Link to="/">
            <img
              src="https://ik.imagekit.io/gulany/gulany/gulany-logo.png?updatedAt=1730004061052"
              className="lg:h-[40px] h-[50px] hidden lg:flex"
              alt="Logo"
            />
            <img
              src="https://ik.imagekit.io/gulany/gulany/Asset%205.svg?updatedAt=1729964013050"
              className="lg:h-[40px] h-[54px] lg:hidden"
              alt="Logo"
            />
          </Link>
        </div>
      </div>
      <a
        href="https://chat.whatsapp.com/HsBpxLwWXmWDeIg9vYSnkp"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-transparent text-[14px] lg:text-[16px] font-normal lg:font-semibold text-white border-[1.5px] lg:border-[2.5px] border-white px-6 py-3 rounded-full hover:border-white/90 transition"
      >
        Notify me
      </a>
    </nav>
  );
};

export default NavBar;
