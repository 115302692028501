import NavBar from "../../../components/navbar/NavBar";
import { useState, useEffect } from "react";
import {
  motion,
  AnimatePresence,
  useScroll,
  useTransform,
} from "framer-motion";
import "../../../App.css";

const HeroSection = () => {
  const { scrollY } = useScroll();
  const x = useTransform(scrollY, [0, 500], [0, 150]); // Translates right by 150px
  const x1 = useTransform(scrollY, [0, 500], [150, 0]); // Translates right by 150px
  const y = useTransform(scrollY, [0, 400], [200, 0]);
  const discountY = useTransform(scrollY, [0, 300], [-170, 0]);
  const discountOpacity = useTransform(scrollY, [0, 300], [0, 1]);
  const [currentWord, setCurrentWord] = useState("easy");
  const words = ["easy", "secure"];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWord((current) => {
        const currentIndex = words.indexOf(current);
        return words[(currentIndex + 1) % words.length];
      });
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  const { scrollYProgress } = useScroll();

  // Add this animation variant
  const discountAnimation = {
    hidden: {
      opacity: 0,
      scale: 0.5,
      y: 50,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <div className="bg-[#FF4500] lg:h-full h-[950px]  relative overflow-hidden">
      <NavBar />
      <div className="container mx-auto px-4 lg:px-20 lg:pb-24">
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
          <div className="lg:mt-1 mt-6">
            <button className="bg-white/20 mb-7 text-[14px] lg:text-[16px] text-white px-6 py-3 lg:px-7 lg:py-3 rounded-full hover:bg-white/30 transition flex items-center gap-1">
              Launching soon
            </button>
            <h1 className="text-white text-[58px] leading-[64px] lg:w-full mb-3 lg:mb-6 myriad-pro-light md:text-6xl lg:text-[93px]">
              <span className="font-bold myriad-pro-bold">Shopping</span> made{" "}
              <AnimatePresence mode="wait">
                <motion.span
                  key={currentWord}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.5 }}
                  className="myriad-pro-bold inline-block"
                >
                  {currentWord}
                </motion.span>
              </AnimatePresence>
            </h1>
            <p className="text-white/90 myriad-pro-regular text-[17px] lg:text-[19px] font-light lg:font-medium mb-10">
              Buy and discover the products you love
            </p>

            {/* Platform Availability */}
            <div className="space-y-4">
              <p className="text-white pt-4 lg:pt-0 text-[15px] lg:text-[16px] myriad-pro-bold mb-4">
                COMING SOON:
              </p>
              <div className="flex space-x-6">
                <div className="flex items-center space-x-1 text-white">
                  <div className="w-8 h-8">
                    <img
                      src="https://ik.imagekit.io/gulany/gulany/Untitled%20design%20(32).png?updatedAt=1730027728502"
                      loading="lazy"
                      className="w-6"
                      alt="Digital Marketing Expert"
                    />
                  </div>
                </div>
                <div className="flex items-center space-x-1 text-white">
                  <div className="w-8 h-8">
                    <img
                      src="https://ik.imagekit.io/gulany/gulany/play.png?updatedAt=1730025478140"
                      loading="lazy"
                      className="w-6"
                      alt="Digital Marketing Expert"
                    />
                  </div>
                </div>
                <div className="flex items-center space-x-1 text-white">
                  <div className="w-8 h-8">
                    <img
                      src="https://ik.imagekit.io/gulany/gulany/apple.png?updatedAt=1730025478559"
                      loading="lazy"
                      className="w-6"
                      alt="apple"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* CTA Section */}

            <div className="flex mt-11 items-center relative">
              <a
                href="https://chat.whatsapp.com/HsBpxLwWXmWDeIg9vYSnkp"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black text-white lg:px-11 px-11 py-5 rounded-full hover:bg-black/90 transition myriad-pro-semibold inline-block text-center cursor-pointer"
              >
                Join our community
              </a>
              <motion.div
                className="absolute right-[-10px] lg:right-0 lg:top-[-140px] top-[-60px]"
                style={{
                  y: discountY,
                  opacity: discountOpacity,
                }}
              >
                <img
                  src="https://ik.imagekit.io/gulany/gulany/Gulany%20landing%20page%20(15).png?updatedAt=1730177246763"
                  loading="lazy"
                  className="lg:w-[300px] w-[200px]"
                  alt="Digital Marketing Expert"
                />
              </motion.div>
            </div>
          </div>

          {/* Right Column - Phone Mockups */}
          <div className="relative h-[600px]">
            <motion.div
              style={{ x: x }}
              className="absolute hidden lg:flex right-[-118px] top-[0.5px] bottom-0"
            >
              <img
                src="https://ik.imagekit.io/gulany/gulany/Gulany%20landing%20page.svg?updatedAt=1730029338579"
                loading="lazy"
                className="w-[560px]"
                alt="Digital Marketing Expert"
              />
            </motion.div>
            <motion.div
              style={{ x: x1 }}
              className="absolute lg:hidden right-[-18px] top-[0.5px] bottom-0"
            >
              <img
                src="https://ik.imagekit.io/gulany/gulany/Gulany%20landing%20page.svg?updatedAt=1730029338579"
                loading="lazy"
                className="w-[300px]"
                alt="Digital Marketing Expert"
              />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
