import { Link } from "react-router-dom";
import {
  facebook,
  instagram,
  linkedin,
  logo,
  logoWhite,
  tiktok,
  x,
} from "../../assets/images";

const socialLinks = [
  {
    href: "https://web.facebook.com/profile.php?id=61568123212269",
    icon: facebook,
    alt: "Facebook",
  },
];

const Footer = () => {
  return (
    <div className="lg:h-[300px] h-[250px] w-full flex justify-center items-end pb-3 bg-black">
      <div className="mx-3 lg:mx-20 pt-[60px] flex flex-col space-y-8 justify-center items-center">
        <img
          src="https://ik.imagekit.io/gulany/gulany/Asset%205.svg?updatedAt=1729964013050"
          className="h-[60px] lg:h-[70px] mt-4"
        />
        <div className="flex flex-row space-x-8 lg:space-x-20">
          {socialLinks.map(({ href, icon, alt }) => (
            <a
              key={alt}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={alt}
            >
              <img src={icon} className="h-[20px] md:h-[20px]" alt={alt} />
            </a>
          ))}
        </div>
        <div className="pt-5">
          <p className="text-center text-[12px] text-white">
            Gulany Trading Limited © 2024. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
