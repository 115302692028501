import React, { useEffect, useRef, useState } from "react";
import {
  Check,
  Star,
  ThumbsUp,
  Clock,
  ChevronLeft,
  ChevronRight,
  ShoppingBag,
  ThumbsUpIcon,
  Eye,
  CheckCheck,
  ShieldCheck,
  CheckCircle,
  Truck,
} from "lucide-react";
import { Link } from "react-router-dom";

interface FormData {
  name: string;
  phone: string;
  street: string;
  city: string;
  province: string;
  quantity: number;
}

interface ProductImage {
  id: number;
  url: string;
  alt: string;
}

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // Set end date to Black Friday
    const endDate = new Date("2024-11-29T00:00:00");

    const timer = setInterval(() => {
      const now = new Date();
      const difference = endDate.getTime() - now.getTime();

      setTimeLeft({
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex justify-start gap-2 mt-2 lg:mt-6">
      {Object.entries(timeLeft).map(([unit, value]) => (
        <div key={unit} className="text-start">
          <div className="bg-white text-[#ff4500] lg:border-2 lg:border-[#ff4500] rounded-lg min-w-[60px] p-2">
            <div className="text-[15px] font-bold text-center">{value}</div>
            <div className="text-[8px] text-gray-600 uppercase text-center font-semibold">
              {unit}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const StockProgress = () => {
  return (
    <div className="w-full mt-4">
      <div className="flex justify-between mb-1">
        <span className="text-sm font-medium text-gray-700">
          Only 3% of stock remaining
        </span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div
          className="bg-[#ff4500] h-2.5 rounded-full"
          style={{ width: "3%" }}
        ></div>
      </div>
    </div>
  );
};

const SmartphoneScreenMagnifier = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    phone: "",
    street: "",
    city: "",
    province: "",
    quantity: 1,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Product images array
  const productImages: ProductImage[] = [
    {
      id: 1,
      url: "https://ik.imagekit.io/gulany/gulany/61nCs6AqAuL._AC_UF894,1000_QL80_FMwebp_.webp?updatedAt=1732604353537",
      alt: "Stanley Cup - Classic Design",
    },
    {
      id: 2,
      url: "https://ik.imagekit.io/gulany/gulany/61QyYqNLOXL.jpg?updatedAt=1732604353011",
      alt: "Stanley Cup - Side View",
    },
    {
      id: 3,
      url: "https://ik.imagekit.io/gulany/gulany/61yeFVbnU0L._AC_UF894,1000_QL80_FMwebp_.webp?updatedAt=1732604353684",
      alt: "Stanley Cup - With Ice",
    },
    {
      id: 4,
      url: "https://ik.imagekit.io/gulany/gulany/61GBUvGbR9L._AC_UF894,1000_QL80_FMwebp_.webp?updatedAt=1732604353337",
      alt: "Stanley Cup - Size Reference",
    },
    {
      id: 5,
      url: "https://ik.imagekit.io/gulany/gulany/714s19mG0dL._AC_UF894,1000_QL80_FMwebp_.webp?updatedAt=1732604353025",
      alt: "Stanley Cup - Color Options",
    },
    {
      id: 6,
      url: "https://ik.imagekit.io/gulany/gulany/61Dg1FdCpxL._AC_SL1500_.jpg?updatedAt=1732604353645",
      alt: "Stanley Cup - In Use",
    },
  ];

  const specifications = [
    { attribute: "Screen Size", value: "12 inch enlarged display" },
    { attribute: "Material", value: "High-quality acrylic" },
    { attribute: "Compatibility", value: "All smartphones 4-7 inches" },
    { attribute: "Magnification", value: "2-4x zoom" },
    { attribute: "Weight", value: "300g" },
    { attribute: "Foldable", value: "Yes" },
    { attribute: "Anti-Blue Light", value: "Yes" },
    { attribute: "Stand Feature", value: "Adjustable viewing angles" },
  ];

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) =>
      prev === 0 ? productImages.length - 1 : prev - 1
    );
  };
  const formRef = useRef<HTMLDivElement>(null);
  const scrollToForm = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) =>
      prev === productImages.length - 1 ? 0 : prev + 1
    );
  };

  const handleThumbnailClick = (index: number) => {
    setCurrentImageIndex(index);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);
  };

  const features = [
    {
      title: "HD Screen Magnification",
      icon: Eye,
      description: "Enlarges your phone screen up to 4x with crystal clarity",
    },
    {
      title: "Universal Compatibility",
      icon: Check,
      description: "Works with all smartphone models",
    },
    {
      title: "Eye Protection",
      icon: ShieldCheck,
      description: "Anti-blue light technology reduces eye strain",
    },
  ];

  const productDetails = {
    name: "HD Smartphone Screen Magnifier Pro",
    colors: ["Black", "White", "Silver"],
    price: "ZMW280",
    originalPrice: "ZMW320",
    discount: "15% OFF",
    shipping: "Free Shipping",
    rating: 4.8,
    reviewCount: 1853,
  };

  const testimonials = [
    {
      name: "Sarah M.",
      text: "Best investment ever! My water stays cold all day long.",
      rating: 5,
    },
    {
      name: "Mike R.",
      text: "Superior quality and amazing temperature retention.",
      rating: 5,
    },
    {
      name: "Jessica K.",
      text: "Stylish, durable, and actually works as advertised!",
      rating: 5,
    },
  ];
  const [viewerCount, setViewerCount] = useState(719);

  useEffect(() => {
    const interval = setInterval(() => {
      const change = Math.floor(Math.random() * 0) - 5; // Random number between -5 and +5
      setViewerCount((prevCount) =>
        Math.max(650, Math.min(800, prevCount + change))
      ); // Keep count between 650-800
    }, 3000); // Update every 3 seconds

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4">
        <Link to="/">
          <img
            src="https://ik.imagekit.io/gulany/gulany/Untitled%20design%20(1).png?updatedAt=1732462700770"
            className="lg:h-[100px] py-2 h-[85px]"
            alt="Logo"
          />
        </Link>
      </div>

      {/* Hero Section */}

      {/* Product Gallery Section */}
      <section className="py-1 bg-white">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Gallery */}
            <div className="space-y-4">
              {/* Main Image */}
              <div className="relative aspect-square bg-gray-100 rounded-lg overflow-hidden">
                <img
                  src={productImages[currentImageIndex].url}
                  alt={productImages[currentImageIndex].alt}
                  className="w-full h-full object-cover"
                />
                {/* Navigation Arrows */}
                <button
                  onClick={handlePrevImage}
                  className="absolute left-2 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-lg hover:bg-white"
                >
                  <ChevronLeft className="w-6 h-6" />
                </button>
                <button
                  onClick={handleNextImage}
                  className="absolute right-2 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-lg hover:bg-white"
                >
                  <ChevronRight className="w-6 h-6" />
                </button>
              </div>
              {/* Thumbnails */}
              <div className="grid grid-cols-6 gap-2">
                {productImages.map((image, index) => (
                  <button
                    key={image.id}
                    onClick={() => handleThumbnailClick(index)}
                    className={`aspect-square rounded-md overflow-hidden border-2 ${
                      currentImageIndex === index
                        ? "border-blue-600"
                        : "border-transparent"
                    }`}
                  >
                    <img
                      src={image.url}
                      alt={`Thumbnail ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </button>
                ))}
              </div>
              <div className="bg-gray-50 p-4 rounded-lg mt-4 mb-4 hidden lg:block">
                <h3 className="font-semibold text-lg mb-6">Our Guarantees</h3>
                <div className="space-y-2 lg:space-y-11">
                  <div className="flex items-start gap-2">
                    <ShieldCheck className="w-7 h-7 text-[#ff4500] mt-1" />
                    <div>
                      <p className="font-medium">7-Day Return Policy</p>
                      <p className="text-sm text-gray-600">
                        Return your unused product within 7 days for a full
                        refund
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start gap-2">
                    <CheckCircle className="w-7 h-7 text-[#ff4500] mt-1" />
                    <div>
                      <p className="font-medium">Money-Back Guarantee</p>
                      <p className="text-sm text-gray-600">
                        100% refund if product doesn't meet described
                        specifications
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start gap-2">
                    <Truck className="w-7 h-7 text-[#ff4500] mt-1" />
                    <div>
                      <p className="font-medium">Delivery Guarantee</p>
                      <p className="text-sm text-gray-600">
                        We deliver anywhere around Zambia
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Product Details */}
            <div className="space-y-6">
              <h2 className="text-3xl font-bold">{productDetails.name}</h2>

              {/* Price */}
              <div className="space-y-2">
                <div className="flex flex-col items-start">
                  <div className="flex items-center space-x-4 mb-2 sm:mb-0">
                    <span className="text-3xl font-bold text-[#ff4500]">
                      {productDetails.price}
                    </span>
                    <span className="text-xl text-gray-500 line-through">
                      {productDetails.originalPrice}
                    </span>
                    <span className="bg-red-100 text-red-600 px-2 py-1 rounded-md font-semibold">
                      {productDetails.discount}
                    </span>
                  </div>
                  <div className="flex flex-row space-x-2 items-center justify-center">
                    <span className="text-sm text-gray-600">
                      <strong className="font-semibold">Ends in:</strong>
                    </span>
                    <CountdownTimer />
                  </div>
                </div>
              </div>

              <StockProgress />

              <div className="flex flex-wrap gap-6 mb-8 p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-2">
                  <ShoppingBag className="w-5 h-5 text-gray-600" />
                  <span className="text-sm text-gray-600">
                    <strong className="font-semibold">6.8K</strong> Sold Already
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <ThumbsUpIcon className="w-5 h-5 text-gray-600" />
                  <span className="text-sm text-gray-600">
                    <strong className="font-semibold">5.5K</strong> Personal
                    Recommendations
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <Eye className="w-5 h-5 text-gray-600" />
                  <span className="text-sm text-gray-600">
                    <strong className="font-semibold">{viewerCount}</strong>{" "}
                    People Viewing This Product Right Now
                  </span>
                </div>
              </div>

              {/* Colors */}
              <div className="space-y-2">
                <p className="font-semibold">Available Colors</p>
                <div className="flex space-x-2">
                  {productDetails.colors.map((color) => (
                    <button
                      key={color}
                      className="px-4 py-2 border rounded-md hover:border-blue-600"
                    >
                      {color}
                    </button>
                  ))}
                </div>
              </div>
              <a
                href="https://wa.me/260769016990?text=Hi%20Gulany,%20I%20would%20like%20to%20order%20a%20Screen%20Magnifier.%20"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full bg-[#ff4500] text-white py-4 rounded-md font-bold hover:bg-[#ff4500]/90 transition-colors mt-6 block text-center"
              >
                Order Now
              </a>
              <div className="bg-gray-50 p-4 rounded-lg mt-4 mb-4 lg:hidden">
                <h3 className="font-semibold text-lg mb-6">Our Guarantees</h3>
                <div className="space-y-6 lg:space-y-11">
                  <div className="flex items-start gap-2">
                    <ShieldCheck className="w-7 h-7 text-[#ff4500] mt-1" />
                    <div>
                      <p className="font-medium">7-Day Return Policy</p>
                      <p className="text-sm text-gray-600">
                        Return your unused product within 7 days for a full
                        refund
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start gap-2">
                    <CheckCircle className="w-7 h-7 text-[#ff4500] mt-1" />
                    <div>
                      <p className="font-medium">Money-Back Guarantee</p>
                      <p className="text-sm text-gray-600">
                        100% refund if product doesn't meet described
                        specifications
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start gap-2">
                    <Truck className="w-7 h-7 text-[#ff4500] mt-1" />
                    <div>
                      <p className="font-medium">Delivery Guarantee</p>
                      <p className="text-sm text-gray-600">
                        We deliver anywhere around Zambia
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="font-semibold lg:mb-2">Product details</p>
              <p className="text-gray-800">
                Transform your smartphone viewing experience with our HD Screen
                Magnifier. Perfect for watching movies, gaming, or reading in
                comfort. The anti-blue light technology protects your eyes while
                the adjustable stand ensures the perfect viewing angle. Ideal
                for everyone who wants to enjoy their phone content on a bigger
                screen.
              </p>

              {/* Capacity */}
              <div>
                <div className="overflow-x-auto">
                  <table className="min-w-full border-collapse">
                    <thead>
                      <tr className="bg-gray-50">
                        <th className="text-left py-3 px-4 border-b border-gray-200 font-semibold">
                          Attribute
                        </th>
                        <th className="text-left py-3 px-4 border-b border-gray-200 font-semibold">
                          Specification
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {specifications.map((spec, index) => (
                        <tr
                          key={index}
                          className="border-b border-gray-200 hover:bg-gray-50"
                        >
                          <td className="py-3 px-4 font-medium">
                            {spec.attribute}
                          </td>
                          <td className="py-3 px-4">{spec.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="lg:hidden container mx-auto px-4">
        <a
          href="https://wa.me/260769016990?text=Hi%20Gulany,%20I%20would%20like%20to%20order%20a%20Screen%20Magnifier.%20"
          target="_blank"
          rel="noopener noreferrer"
          className="w-full bg-[#ff4500] text-white py-4 rounded-md font-bold hover:bg-[#ff4500]/90 transition-colors mt-6 block text-center"
        >
          Order Now
        </a>
      </div>

      {/* Features Section */}
      <section className="py-5 ">
        <div className="container mx-auto ">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="bg-white p-6 rounded-lg text-center">
                <feature.icon className="w-12 h-12 mx-auto mb-4 text-[#ff4500]" />
                <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Testimonials Section */}

      {/* Lead Capture Form */}
      <section ref={formRef} className="py-10 hidden">
        <div className="container mx-auto px-4 max-w-md">
          <div className="bg-white p-8 rounded-lg shadow-md">
            <h2 className="text-3xl font-bold text-center mb-6">
              Order Your Stanley Cup Today
            </h2>
            {isSubmitted ? (
              <div className="bg-green-50 border-green-200">
                <div className="text-green-800 text-center">
                  Thanks for your interest! We'll contact you shortly with
                  delivery details.
                </div>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    value={formData.phone}
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                  />
                </div>
                <div>
                  <label
                    htmlFor="street"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Street Address
                  </label>
                  <input
                    type="text"
                    id="street"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    value={formData.street}
                    onChange={(e) =>
                      setFormData({ ...formData, street: e.target.value })
                    }
                  />
                </div>

                <div>
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    City
                  </label>
                  <input
                    type="text"
                    id="city"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    value={formData.city}
                    onChange={(e) =>
                      setFormData({ ...formData, city: e.target.value })
                    }
                  />
                </div>

                <div>
                  <label
                    htmlFor="province"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Province
                  </label>
                  <input
                    type="text"
                    id="province"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    value={formData.province}
                    onChange={(e) =>
                      setFormData({ ...formData, province: e.target.value })
                    }
                  />
                </div>
                <div>
                  <label
                    htmlFor="quantity"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Quantity
                  </label>
                  <input
                    type="number"
                    id="quantity"
                    min="1"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    value={formData.quantity}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        quantity: parseInt(e.target.value),
                      })
                    }
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-[#ff4500] text-white py-3 rounded-md font-bold hover:bg-[#f87647] transition-colors"
                >
                  Get Special Offer
                </button>
                <p className="text-xs text-gray-500 text-center mt-4">
                  By submitting this form, you agree to receive marketing
                  communications from us.
                </p>
              </form>
            )}
          </div>
        </div>
      </section>
      <div className="lg:hidden container mx-auto px-4">
        <a
          href="https://wa.me/260769016990?text=Hi%20Gulany,%20I%20would%20like%20to%20order%20a%20Screen%20Magnifier.%20"
          target="_blank"
          rel="noopener noreferrer"
          className="w-full bg-[#ff4500] text-white py-4 rounded-md font-bold hover:bg-[#ff4500]/90 transition-colors mt-6 block text-center"
        >
          Order Now
        </a>
      </div>
    </div>
  );
};

export default SmartphoneScreenMagnifier;
