import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navbar/NavBar";
import About from "./components/About";
import BenefitSection from "./components/BenefitSection";
import FAQSection from "./components/FAQSection";
import HeroSection from "./components/HeroSection";

const Home = () => {
  return (
    <div>
      <div>
        <section>
          <HeroSection />
        </section>
        <section>
          <About />
        </section>
        <section>
          <BenefitSection />
        </section>
        <section>
          <FAQSection />
        </section>
        <section>
          <Footer />
        </section>
      </div>
    </div>
  );
};

export default Home;
