import { useScroll, useTransform, motion } from "framer-motion";
import SlideInOnScroll from "../assets/animations/SlideInScroll";
import "../../../App.css";

const BenefitSection: React.FC = () => {
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [300, 800], [200, 0]);

  return (
    <div>
      <section className="md:py-20 pt-10 bg-black min-h-[400px] md:min-h-[630px] overflow-hidden">
        <div className="container mx-auto px-4 md:px-24">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="w-full lg:w-1/2 mb-8 lg:mb-0 order-1">
              <h1 className="text-white myriad-pro-light text-5xl md:text-6xl lg:text-[78px] mb-4 md:mb-6 text-start">
                Find <span className="myriad-pro-bold">products</span> you love
              </h1>
              <p className="text-white text-[16px] myriad-pro-regular md:text-[19px] text-start font-[400]">
                From top brands to local favorites, handpicked for quality, and
                delivered right to your door
              </p>
              <div className="flex justify-start items-start mt-6">
                <a
                  href="https://chat.whatsapp.com/HsBpxLwWXmWDeIg9vYSnkp"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="border-white border-2 mt-5 md:mt-7 text-white px-8 md:px-20 py-3 md:py-5 rounded-full hover:bg-black/90 transition font-semibold"
                >
                  Gain early access
                </a>
              </div>
            </div>
            <div className="w-full lg:w-1/2 relative order-2 mt-6 lg:mt-0">
              <SlideInOnScroll id="101042" delay={100}>
                <motion.img
                  style={{ y }}
                  src="https://ik.imagekit.io/gulany/gulany/neaker.png?updatedAt=1730106659409"
                  alt="About Us"
                  className="w-full lg:hidden object-cover relative lg:absolute lg:left-[135px] top-[5px]"
                />
              </SlideInOnScroll>
              <motion.img
                style={{ y }}
                src="https://ik.imagekit.io/gulany/gulany/neaker.png?updatedAt=1730095756309"
                alt="About Us"
                className="w-full hidden lg:flex max-w-[650px] object-cover relative lg:absolute lg:left-[135px] lg:top-[-180px]"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="py-10 md:py-20 bg-white min-h-[400px] md:min-h-[730px]">
        <div className="container mx-auto px-4 md:px-16">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="w-full lg:w-1/2 mb-8 lg:mb-0 order-1 lg:order-2">
              <h1 className="text-[#262626] myriad-pro-light text-5xl md:text-6xl lg:text-[78px] mb-4 md:mb-6 text-start lg:text-end">
                Shop with a {""}
                <span className="myriad-pro-bold">peace</span> of mind
              </h1>
              <p className="text-[#575757] myriad-pro-regular text-[16px] md:text-[19px] text-start lg:text-end">
                From secure payments to easy returns and refunds, every step is
                crafted to protect your shopping experience
              </p>
              <div className="flex justify-start lg:justify-end mt-6 items-start lg:items-end">
                <a
                  href="https://chat.whatsapp.com/HsBpxLwWXmWDeIg9vYSnkp"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-black mt-5 md:mt-7 text-white px-8 md:px-20 py-3 md:py-5 rounded-full hover:bg-black/90 transition font-semibold"
                >
                  Gain early access
                </a>
              </div>
            </div>
            <div className="w-full lg:w-1/2 relative order-2 lg:order-1 mt-6 lg:mt-0">
              <SlideInOnScroll id="1010432" delay={100}>
                <motion.img
                  style={{ y }}
                  src="https://ik.imagekit.io/gulany/gulany/2.png?updatedAt=1730023692711"
                  alt="About Us"
                  className="w-full max-w-[60%] h-auto object-cover relative right-[-80px] lg:right-10 lg:absolute lg:top-[-150px]"
                />
              </SlideInOnScroll>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BenefitSection;
