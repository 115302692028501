import React, { useState } from "react";
import SlideInOnScroll from "../assets/animations/SlideInScroll";

const FAQSection = () => {
  const [openAccordions, setOpenAccordions] = useState<number[]>([]);

  const toggleAccordion = (index: number) => {
    setOpenAccordions((prevOpenAccordions) => {
      if (prevOpenAccordions.includes(index)) {
        return prevOpenAccordions.filter((i) => i !== index);
      } else {
        return [...prevOpenAccordions, index];
      }
    });
  };

  const faqData = [
    {
      question: "What is Gulany?",
      answer:
        "Gulany is an all-in-one online store offering a wide range of quality products directly from us to you. We prioritize convenience, security, and top-notch service to make shopping easy and enjoyable.",
    },
    {
      question: "How does Gulany work?",
      answer:
        "Gulany is the sole seller on the platform, allowing us to control quality and customer experience from start to finish. Simply browse, add items to your cart, and check out. We handle the rest, including fast delivery to your doorstep.",
    },
    {
      question: "What products does Gulany offer?",
      answer:
        "We offer a diverse selection of products across multiple categories. Our inventory is carefully curated to ensure high standards of quality, so you can find reliable and trusted items all in one place.",
    },
    {
      question: "When will Gulany be live?",
      answer:
        "Gulany will soon be available on web, iOS, and Android. Join our waitlist community to be notified as soon as it launches, and enjoy a seamless shopping experience on any device.",
    },
  ];

  return (
    <div className="flex mt-8 flex-col justify-center items-center lg:my-[60px] w-full mb-6">
      <div>
        <SlideInOnScroll id="10102" delay={100}>
          <div className="flex justify-start items-start w-full">
            <button className=" text-[#262626] text-6xl mb-6 md:text-6xl lg:text-[78px] px-8 py-4 font-bold  text-start">
              FAQ
            </button>
          </div>
        </SlideInOnScroll>
      </div>

      <div className="w-full lg:mt-[70px] mt-[1px] px-4 lg:px-20">
        <SlideInOnScroll id="101023" delay={100}>
          <div className="flex flex-col">
            {faqData.map((faq, index) => (
              <div
                key={index}
                className="border-b border-gray-300 last:border-none"
              >
                <div
                  className="py-6 cursor-pointer"
                  onClick={() => toggleAccordion(index)}
                >
                  <div className="flex flex-row items-center justify-between">
                    <div>
                      <h1 className="font-semibold text-[16px] text-gray-700">
                        {faq.question}
                      </h1>
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={`size-6 transition-transform ${
                          openAccordions.includes(index) ? "rotate-45" : ""
                        }`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </div>
                  </div>
                  {openAccordions.includes(index) && (
                    <div className="pt-4">
                      <p className="text-gray-500 text-[16px] lg:w-[70%]">
                        {faq.answer}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </SlideInOnScroll>
      </div>
    </div>
  );
};

export default FAQSection;
